<template>
  <div>
    <div class="row align-items-end">
      <div class="col">
        <label for="">Nom</label>
        <input type="text" v-model="section.fullName" class="form-control" />
      </div>
      <div class="col-3">
        <label for="">Dépôt <span class="text-danger">*</span> </label>
        <select v-model="section.deposit_reference" class="form-select">
          <option
            v-for="(deposit, index) in deposits"
            :key="index++"
            :value="deposit.reference"
          >
            {{ deposit.fullName }}
          </option>
        </select>
      </div>

      <div class="col-3">
        <label for="">Fournisseur <span class="text-danger">*</span> </label>
        <select v-model="section.supplier_reference" class="form-select">
          <option value=""></option>
          <option
            v-for="(supplier, index) in suppliers"
            :key="index++"
            :value="supplier.reference"
          >
            {{ supplier.society }}
          </option>
        </select>
      </div>
    </div>

    <div class="row mt-3 justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(section)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // section: {},
    };
  },
  computed: {
    ...mapGetters("supplier", {
      suppliers: "getAll",
    }),
    ...mapGetters("section", {
      section: "getSection",
    }),
    ...mapGetters("deposit", {
      deposits: "getAll",
    }),
  },
  methods: {
    async save(data) {
      let section = data;
      await this.$confirm({
        message: "Vous voulez Modifier la categorie  " + section.fullName,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("section/update", section);
          }
        },
      });
    },
    async empty() {},
  },
  async beforeMount() {
    await this.$store.dispatch("supplier/getAll");
    await this.$store.dispatch("deposit/getAll");
    await this.$store.dispatch("section/show", this.$route.params.reference);
  },
};
</script>
